import {createStore} from 'vuex';
import api from "@api";
import constants from "@constants";
import localStorageClient from "@state/local-storage-client.js";
import {ObjectHelper} from "@utils";

const shortInterval = 5 * 60 * 1000; // 5 min

export const actions = {
	LoadAccountantNotifications: 'accountantNotifications/loadNotifications',
	FetchAccountantNotifications: 'accountantNotifications/fetchNotifications',
	CompleteAccountantNotification: 'accountantNotifications/completeNotification',
	LoadTransactionTypes: 'transactionTypes/loadTransactionTypes',
	LoadTransactionSpecifications: 'transactionSpecifications/loadTransactionSpecifications',
	LoadSettings: 'settings/load',
	LoadAccountant: 'accountant/loadAccountant',
	LoadAccountants: 'accountant/loadAccountants',
	LoadTodoCount: 'todos/loadCount',
	LoadTodoCountPriorityHigh: 'todos/loadCountPriorityHigh',
	LoadBankTransactionCount: 'bankTransactions/loadBankTransactionCount',
	LoadBookingPropositionCount: 'bookingPropositions/loadCount',
	LoadUserSettings: 'user/loadUserSettings',
	RefreshUserSettings: 'user/getUserSettings',
	LoadMessages: 'systemMessage/loadMessages',
	FetchTodoCount: 'todos/getTodoCount',
	FetchTodoCountPriorityHigh: 'todos/getTodoCountPriorityHigh',
	SetTodoDialog: 'todos/setTodoDialog',
	FetchBankTransactionCount: 'bankTransactions/getBankTransactionCount',
	FetchBookingPropositionCount: 'bookingPropositions/getBookingPropositionCount',
	SaveSettings: 'settings/save',
	SetBotAdvice: 'bot/setAdvice',
	SetBotPositionLeft: 'bot/setBotPositionLeft',
	SetNordigenConnect: 'bankTransactions/setNordigenConnect',
	LoadNordigenConnect: 'bankTransactions/loadNordigenConnect',
}

export const getters = {
	AccountantNotificationsWithExpense: 'accountantNotifications/accountantNotificationsWithExpense',
	AccountantNotificationsWithRevenue: 'accountantNotifications/accountantNotificationsWithRevenue',
	TransactionSpecificationsByTransactionTypeId: 'transactionSpecifications/transactionSpecificationsByTransactionTypeId',
	CurrentAccountantId: 'accountants/currentAccountantId'
}

const moduleSettings = {
	namespaced: true,
	state: {
		storageKey: 'BusinessSettingsKey',
		showInvoicePayLink: false,
		showRecurringPayments: false,
		showReminders: false,
		showExpenseNumberFormat: false,
		showInvoiceReference: false,
		showInvoiceSetPaid: false,
		showConceptInvoices: true,
		showBankTransactionImport: false,
		showPaymentLinkInOverview: false,
		showEmailInvoice: true,
		showBot: false,
		showQuotations: false,
		showDorkmail: false,
		invoiceNumberFormat: null,
		expenseNumberFormat: null,
		quotationNumberFormat: null,
		taxTimeSlot: null,
		botSettings: {},
		showExpensesOverview: true,
		showMyGoals: true,
		showVatPrognosis: true,
		showInvestments: true,
		showSubscriptions: false,
		showIntegratedTransactions: false,
		allowCc: true,
		allowReplyTo: true,
		showHourCriterion: true,
		showTopRelations: true,
		showTopSales: true,
	},
	mutations: {
		SET_SETTINGS: (state, settings) => {
			state.invoiceNumberFormat = settings.invoiceNumberFormat;
			state.quotationNumberFormat = settings.quotationNumberFormat;
			state.expenseNumberFormat = settings.expenseNumberFormat;
			state.showInvoicePayLink = settings.showInvoicePayLink;
			state.showRecurringPayments = settings.showRecurringPayments;
			state.showReminders = settings.showReminders;
			state.showInvoiceReference = settings.showInvoiceReference;
			state.showInvoiceSetPaid = settings.showInvoiceSetPaid;
			state.showConceptInvoices = settings.showConceptInvoices;
			state.showPeriodicInvoices = settings.showPeriodicInvoices;
			state.showExpenseNumberFormat = settings.showExpenseNumberFormat;
			state.showBankTransactionImport = settings.showBankTransactionImport;
			state.showPaymentLinkInOverview = settings.showPaymentLinkInOverview;
			state.showEmailInvoice = settings.showEmailInvoice;
			state.showBot = settings.showBot;
			state.showQuotations = settings.showQuotations;
			state.showDorkmail = settings.showDorkmail;
			state.showExpensesOverview = settings.showExpensesOverview;
			state.showMyGoals = settings.showMyGoals;
			state.showVatPrognosis = settings.showVatPrognosis;
			state.showInvestments = settings.showInvestments;
			state.showSubscriptions = settings.showSubscriptions;
			state.showIntegratedTransactions = settings.showIntegratedTransactions;
			state.allowCc = settings.allowCc;
			state.allowReplyTo = settings.allowReplyTo;
			state.showHourCriterion = settings.showHourCriterion;
			state.showTopRelations = settings.showTopRelations;
			state.showTopSales = settings.showTopSales;
			state.botSettings = (typeof settings.botSettings === 'string' ? JSON.parse(settings.botSettings) : settings.botSettings) || {};
			localStorageClient.save(state.storageKey, state);
		}
	},
	actions: {
		load: ({commit, state}) => {

			localStorageClient.load(state.storageKey).then((result) => {
				if (result && result.data && Object.keys(result.data).length > 0) {
					commit('SET_SETTINGS', result.data);
					return Promise.resolve();
				}

				return api.get('/api/Company/GetBusinessSettings').then((response) => {
					commit('SET_SETTINGS', response.data);
				});
			});
		},
		save: ({commit}, settings) => {
			let model = ObjectHelper.clone(settings);
			model.botSettings = JSON.stringify(settings.botSettings || {});

			return api.post('/api/Company/SaveBusinessSettings', model).then(() => {
				commit('SET_SETTINGS', settings);
			});
		}
	}
}

const moduleBot = {
	namespaced: true,
	state: {
		advice: null,
		positionLeft: false,
	},
	mutations: {
		SET_ADVICE: (state, payload) => {
			state.advice = payload.advice;
		},
		SET_POSITIONLEFT: (state, value) => {
			state.positionLeft = value;
		}
	},
	actions: {
		setAdvice: ({commit}, advice) => {
			commit('SET_ADVICE', advice);
		},
		setBotPositionLeft: ({commit}, value) => {
			commit('SET_POSITIONLEFT', value);
		},
	}
}

const moduleAccountantNotifications = {
	namespaced: true,
	state: {
		notifications: null,
		suggestions: null
	},
	getters: {
		accountantNotificationsWithExpense: state => {
			return (state.notifications || []).filter(x => x.discriminator === constants.targets.expense);
		},
		accountantNotificationsWithRevenue: state => {
			return (state.notifications || []).filter(x => x.discriminator === constants.targets.revenue);
		},
	},
	mutations: {
		SET_ACCOUNTANT_NOTIFICATIONS: (state, notifications) => {
			state.notifications = notifications;
			state.suggestions = notifications.filter(x => x.isSuggestion);
			localStorageClient.save('SET_ACCOUNTANT_NOTIFICATIONS', notifications);
		}
	},
	actions: {
		loadNotifications: ({commit, dispatch}) => {
			return localStorageClient.load('SET_ACCOUNTANT_NOTIFICATIONS').then((result) => {
				if (result && result.data && Array.isArray(result.data)) {
					commit('SET_ACCOUNTANT_NOTIFICATIONS', result.data);
					return Promise.resolve();
				}

				return dispatch('fetchNotifications');
			});
		},
		fetchNotifications: ({commit}) => {
			return api.get('/api/Notification/GetOpenAccountantNotifications').then((response) => {
				commit('SET_ACCOUNTANT_NOTIFICATIONS', response.data);
			});
		},
		completeNotification: ({dispatch}, notification) => {
			return api.post('/api/Notification/CompleteAccountantNotification', notification).then(() => {
				return dispatch('fetchNotifications');
			});
		}
	}
}

const moduleAccountant = {
	namespaced: true,
	state: {
		accountant: null,
		accountants: null,
		storageKeyAccountant: 'SET_ACCOUNTANT',
		storageKeyAccountants: 'SET_ACCOUNTANTS',
	},
	mutations: {
		SET_ACCOUNTANT: (state, value) => {
			state.accountant = value;
			localStorageClient.save(state.storageKeyAccountant, value);
		},
		SET_ACCOUNTANTS: (state, value) => {
			state.accountants = value;
			localStorageClient.save(state.storageKeyAccountants, value);
		}
	},
	actions: {
		loadAccountants: ({commit, state}) => {
			return localStorageClient.load(state.storageKeyAccountants).then((result) => {
				if (result && result.data && Object.keys(result.data).length > 0) {
					commit('SET_ACCOUNTANTS', result.data);
					return Promise.resolve();
				}

				return api.get('/api/Accountant/GetAccountantItems').then((response) => {
					commit('SET_ACCOUNTANTS', response.data);
				});
			});
		},
		loadAccountant: ({commit, state}) => {

			localStorageClient.load(state.storageKeyAccoutant).then((result) => {
				if (result && result.data && Object.keys(result.data).length > 0) {
					commit('SET_ACCOUNTANT', result.data);
					return Promise.resolve();
				}

				return api.get('/api/company/getaccountant').then((response) => {
					commit('SET_ACCOUNTANT', response.data);
				});
			});
		}
	}
}

const moduleSystemMessage = {
	namespaced: true,
	state: {
		message: null
	},
	mutations: {
		SET_MESSAGE: (state, message) => {
			state.message = message;
		},
		SET_MESSAGE_AND_STORE: (state, message) => {
			state.message = message;
			localStorageClient.save('SET_MESSAGE', state.message, shortInterval);
		}
	},
	actions: {
		loadMessages: ({commit}) => {
			return localStorageClient.load('SET_MESSAGE').then((result) => {
				if (result && result.data) {
					commit('SET_MESSAGE', result.data);
					return Promise.resolve();
				}

				return api.get('/api/user/GetActiveSystemMessage').then((response) => {
					commit('SET_MESSAGE_AND_STORE', response.data);
				});
			});
		}
	}
}

const moduleUser = {
	namespaced: true,
	state: {
		storageKey: 'UserSettings',
		settings: null
	},
	mutations: {
		SET_SETTINGS: (state, settings) => {
			state.settings = settings;
			localStorageClient.save(state.storageKey, settings);
		}
	},
	actions: {
		loadUserSettings: ({dispatch, commit, state}) => {

			return localStorageClient.load(state.storageKey).then((result) => {
				if (result && result.data) {
					commit('SET_SETTINGS', result.data);
					return Promise.resolve();
				}

				return dispatch('getUserSettings');
			});
		},
		getUserSettings: ({commit}) => {
			return api.get('/api/User/GetUserSettings').then((response) => {
				if (response.data.companyName) {
					commit('SET_SETTINGS', response.data);
				}
			});
		}
	}
}

const moduleTodos = {
	namespaced: true,
	state: {
		count: null,
		countPriorityHigh: null,
		todoDialog: false,
	},
	getters: {
		count: (state) => state.count,
		countPriorityHigh: (state) => state.countPriorityHigh,
		todoDialog: (state) => state.todoDialog,
	},
	mutations: {
		SET_COUNT: (state, count) => state.count = count,
		SET_COUNTPRIORITYHIGH: (state, countPriorityHigh) => state.countPriorityHigh = countPriorityHigh,
		SET_TODODIALOG: (state, todoDialog) => state.todoDialog = todoDialog,
	},
	actions: {
		loadCount: ({state, dispatch}) => {
			if (state.count == null) {
				return dispatch('getTodoCount');
			}

			return Promise.resolve();
		},
		getTodoCount: ({commit}) => {
			return api.get('/api/Todo/GetTodoCount').then((response) => {
				commit('SET_COUNT', response.data);
			});
		},
		loadCountPriorityHigh: ({state, dispatch}) => {
			if (state.countPriorityHigh == null) {
				return dispatch('getTodoCountPriorityHigh');
			}

			return Promise.resolve();
		},
		getTodoCountPriorityHigh: ({commit}) => {
			return api.get('/api/Todo/GetCountPriorityHigh').then((response) => {
				let count = response.data;
				commit('SET_COUNTPRIORITYHIGH', count);
				if (count > 0) {
					commit('SET_TODODIALOG', true);
				}
			});
		},
		setTodoDialog: ({commit}, todoDialog) => {
			commit('SET_TODODIALOG', todoDialog);
		},
	}
}

const moduleBookingPropositions = {
	namespaced: true,
	state: {
		count: null
	},
	getters: {
		count: (state) => state.count
	},
	mutations: {
		SET_COUNT: (state, count) => state.count = count
	},
	actions: {
		loadCount: ({state, dispatch}) => {
			if (state.count == null) {
				return dispatch('getBookingPropositionCount');
			}

			return Promise.resolve();
		},
		getBookingPropositionCount: ({commit}) => {
			return api.get('/api/BookingProposition/GetBookingPropositionCount').then((response) => {
				commit('SET_COUNT', response.data);
			});
		}
	}
}

const moduleBankTransactions = {
	namespaced: true,
	state: {
		count: null,
		connect: null,
		storageKey: 'NORDIGEN_CONNECT'
	},
	mutations: {
		SET_COUNT: (state, count) => state.count = count,
		SET_NORDIGEN_CONNECT: (state, connect) => {
			state.connect = connect
			localStorageClient.save(state.storageKey, connect);
		}
	},
	actions: {
		setNordigenConnect: ({commit}, connect) => {
			commit('SET_NORDIGEN_CONNECT', connect);
		},
		loadNordigenConnect({state, commit}) {

			localStorageClient.load(state.storageKey).then((result) => {
				if (result && result.data && Object.keys(result.data).length > 0) {
					commit('SET_NORDIGEN_CONNECT', result.data);
				}

				return Promise.resolve();
			});

			return Promise.resolve();
		},
		loadBankTransactionCount: ({state, dispatch}) => {
			if (state.count == null) {
				return dispatch('getBankTransactionCount');
			}

			return Promise.resolve();
		},
		getBankTransactionCount: ({commit}) => {
			return api.get('/api/Banking/GetOpenBankTransactionCount').then((response) => {
				commit('SET_COUNT', response.data);
			});
		}
	}
}

const moduleTransactionTypes = {
	namespaced: true,
	state: {
		transactionTypes: null
	},
	getters: {
		transactionTypes: (state) => state.transactionTypes
	},
	mutations: {
		SET_TRANSACTIONTYPES: (state, transactionTypes) => state.transactionTypes = transactionTypes
	},
	actions: {
		loadTransactionTypes: ({state, commit}) => {
			if (state.transactionTypes == null) {
				return api.get('/api/transaction/GetAllTransactionTypes', {
					params: {reference: constants.reference.expense}
				}).then((response) => {
					commit('SET_TRANSACTIONTYPES', response.data);
				});
			}

			return Promise.resolve();
		}
	}
}

const moduleTransactionSpecifications = {
	namespaced: true,
	state: {
		transactionSpecifications: null
	},
	getters: {
		transactionTypes: (state) => state.transactionTypes,
		transactionSpecificationsByTransactionTypeId: (state) => transactionTypeId => {
			return (state.transactionSpecifications || []).filter(x => x.transactionTypeId === transactionTypeId);
		}
	},
	mutations: {
		SET_TRANSACTIONSPECIFICATIONS: (state, transactionSpecifications) => state.transactionSpecifications = transactionSpecifications
	},
	actions: {
		loadTransactionSpecifications: ({state, commit}) => {
			if (state.transactionSpecifications == null) {
				return api.get('/api/transaction/GetAllTransactionSpecifications').then((response) => {
					commit('SET_TRANSACTIONSPECIFICATIONS', response.data);
				});
			}

			return Promise.resolve();
		}
	}
}

const hourRegistrationModule = {
	namespaced: true,
	state: {
		startTime: null,
		hourRegistrationId: null,
		refreshOverview: false
	},
	getters: {},
	mutations: {
		SET_HOURREGISTRATIONID: (state, value) => state.hourRegistrationId = value,
		SET_STARTTIME: (state, value) => state.startTime = value,
		SET_REFRESHOVERVIEW: (state, value) => state.refreshOverview = value
	},
	actions: {
		cancelStopwatch({commit}) {
			commit('SET_STARTTIME', null);
			commit('SET_HOURREGISTRATIONID', null);
		},
		stopStopwatch({commit, state}) {
			api.post(`/api/HourRegistration/SetEndTime/${state.hourRegistrationId}`
			).then(() => {
				commit('SET_STARTTIME', null);
				commit('SET_HOURREGISTRATIONID', null);
				commit('SET_REFRESHOVERVIEW', true);
				return Promise.resolve();
			})
		},
		setHourRegistrationId({commit}, payload) {
			commit('SET_HOURREGISTRATIONID', payload.value);
			return Promise.resolve();
		},
		setStartTime({commit}, payload) {
			commit('SET_STARTTIME', payload.value);
			return Promise.resolve();
		},
		refreshOverview({commit}) {
			commit('SET_REFRESHOVERVIEW', true);
			return Promise.resolve();
		},
		cancelRefreshOverview({commit}) {
			commit('SET_REFRESHOVERVIEW', false);
		}
	}
}

export const store = createStore({
	strict: true,
	modules: {
		transactionTypes: moduleTransactionTypes,
		transactionSpecifications: moduleTransactionSpecifications,
		settings: moduleSettings,
		bot: moduleBot,
		accountant: moduleAccountant,
		accountantNotifications: moduleAccountantNotifications,
		todos: moduleTodos,
		bookingPropositions: moduleBookingPropositions,
		bankTransactions: moduleBankTransactions,
		user: moduleUser,
		systemMessage: moduleSystemMessage,
		hourRegistration: hourRegistrationModule
	}
});

