import moment from "moment";
import dateTime from "@types/date-time";
import {StringHelper} from "@utils";

export class DateHelper {

	/**
	 * @param date Date
	 * @param days Number
	 * @returns {Date}
	 */
	static addDays(date, days) {
		let newDate = new Date(date);
		newDate.setDate(newDate.getDate() + days);
		return newDate;
	}

	static getEndOfYear(date) {
		date = date || new Date();
		return moment(date.getFullYear() + "-12-31 23:59").toDate();
	}

	static formatDate(year, unformatted, time) {
		if (!time) {
			time = "00:00:00";
		}

		return year + "-" + unformatted + " " + time;
	};

	static formatTime(date) {
		if (typeof date === "string") {
			date = new Date(date);
		}

		if (date instanceof Date) {
			return date.toLocaleTimeString("nl", { hour: "2-digit", minute: "2-digit" });
		}

		return date;
	}

	static GetEndOfDay(date) {
		return moment(date).set("hour", 23).set("minute", 59).set("second", 59).toDate();
	}

	static toLocalDateTime(date) {
		if (date == null) {
			return date;
		}

		return moment(date).set("hour", 12).toDate();
	};

	static getMonthYearDisplay(selectedYear, selectedMonth) {
		return StringHelper.toProperCase(moment([selectedYear, selectedMonth]).format("MMMM YYYY"));
	}

	static toISOString(val) {
		return moment(val).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
	}

	static format(value, format) {
		if (this.isDate(value)) {
			return dateTime(value).format(format);
		}

		return value;
	}

	static isDate(value) {
		return (new Date(value) !== "Invalid Date") && !isNaN(new Date(value));
	}

	static getIncomeTaxYearOptions({ startYear, endYear } = {}) {
		if (!startYear) {
			startYear = 2016;
		}

		if (!endYear) {
			const date = new Date();
			const month = date.getMonth();

			endYear = date.getFullYear();
			if (month < 11) {
				endYear--;
			}
		}

		const years = [];
		for (let year = startYear; year <= endYear; year++) {
			years.push(year);
		}

		return years;
	}

	static getCurrentYear() {
		return new Date().getFullYear();
	}

	/**
	 * @param date Date
	 * @returns Number
	 */
	static getWeekNumber(date) {
		const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
		const dayOfWeek = utcDate.getUTCDay() || 7;
		const daysToThursday = 4 - dayOfWeek;
		utcDate.setUTCDate(utcDate.getUTCDate() + daysToThursday);
		const yearStart = new Date(Date.UTC(utcDate.getUTCFullYear(), 0, 1));
		const millisecondsInDay = 86400000;
		const daysBetween = (utcDate - yearStart) / millisecondsInDay;
		return Math.ceil((daysBetween + 1) / 7);
	}

	static createDay(day) {
		let now = new Date();
		return {
			name: this.getWeekDayName(day.getDay()),
			dayNumber: day.getDate(),
			date: day,
			isCurrent: this.sameDay(day, now)
		};
	}

	static sameDay(day1, day2) {
		return day1.setHours(0, 0, 0, 0) === day2.setHours(0, 0, 0, 0);
	}

	static getWeekDayName(dayNumber) {
		switch (dayNumber) {
			case 0:
				return "zondag";
			case 1:
				return "maandag";
			case 2:
				return "dinsdag";
			case 3:
				return "woensdag";
			case 4:
				return "donderdag";
			case 5:
				return "vrijdag";
			case 6:
				return "zaterdag";
			case 7:
				return "zondag";
		}
	}

	static createTimeString(rawTime) {
		const defaultTime = "00:00";

		const strippedValue = rawTime.replace(/[^0-9:]/g, "");
		if (StringHelper.isNullOrWhitespace(strippedValue)) {
			return defaultTime;
		}

		const match = /^(\d{0,2})(?::(\d{1,2})|(\d{2}))?$/.exec(strippedValue);
		if (!match) {
			return defaultTime;
		}

		const hour = match[1];
		const minute = match[2] || match[3] || "";

		const result = `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
		if (!this.validateTime(result)) {
			return defaultTime;
		}

		return result;
	}

	static validateTime(value) {
		return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
	}

	static getWeekDays(inputDay) {
		const selectedDay = (inputDay && dateTime(inputDay)) || dateTime();
		const offset = -(selectedDay.getDay() + 6) % 7;

		const dayNames = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
		return dayNames.reduce((mappedWeek, dayName, dayNumber) => {
			let day = selectedDay.add(offset + dayNumber, "day");
			mappedWeek[dayName] = this.createDay(day);
			return mappedWeek;
		}, {});
	}
}