export class ObjectHelper {
	static clone(obj) {
		return JSON.parse(JSON.stringify(obj), this.dateReviver);
	}

	static cloneWithValues(obj) {
		return Object.assign({}, obj);
	}

	static isChanged(initialObject, mutatedObject, keysToSkip) {
		let keys1 = Object.keys(initialObject).filter(x => !keysToSkip.includes(x));
		let keys2 = Object.keys(mutatedObject).filter(x => !keysToSkip.includes(x));
		let isChanged = false;

		if (keys1.length !== keys2.length) {
			isChanged = true;
		}

		keys1.forEach(key => {
			let value1 = initialObject[key];
			let value2 = mutatedObject[key];
			if (value1 !== value2) {
				isChanged = true;
			}
		});

		return isChanged;
	}

	static dateReviver(key, value) {

		if ("string" === typeof value && /^\d{4}-[01]\d-[0-3]\dT[012]\d(?::[0-6]\d){2}\.\d{3}Z$/.test(value)) {
			let date = new Date(value);

			if (+date === +date) {
				return date;
			}
		}
		return value;
	}
}